import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { version } from '../../../../../package.json'
import { Icon, Typography } from '../../../../Components'
import { LazyImage } from '../../../../Components/LazyImage'
import { useOnClickOutside } from '../../../../Hooks/useClickedOutside/useOnClickOutside'
import { ucFirst } from '../../../../Lib/Formatters/stringHelpers'
import { useGetPermissionPerNavigationItemQuery } from '../../../../Lib/graphql'
import { platform } from '../../../../Lib/platform'
import SessionExpiredPage from '../../../../Pages/ErrorPages/401'
import InternalServerErrorPage from '../../../../Pages/ErrorPages/500'
import { MediaQueriesContext, SidebarCollapsedContext, ThemeContext } from '../../../../Providers'
import { BreadcrumbsProvider } from '../../../../Providers/BreadcrumbsProvider'
import { ROUTE_NAMES } from '../../interfaces'
import { routes } from '../../routes'
import { NavigationItem } from './components'
import { Content } from './components/Content'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

// The complexity rule triggers, but there is not much really going on here.
// eslint-disable-next-line sonarjs/cognitive-complexity
export const SideBar: React.FC<IProps> = ({ isVisible, children }): JSX.Element | null => {
  const environment = import.meta.env.REACT_APP_ENVIRONMENT
  const isProduction = environment === 'production'
  const showEnvVersion = !isProduction
  const { t } = useTranslation()
  const navSidebar = useRef(null)
  const { currentTheme, isDarkSidebar } = useContext(ThemeContext)
  const { data, loading, error } = useGetPermissionPerNavigationItemQuery({
    fetchPolicy: 'network-only',
  })

  const { sidebarCollapsed, toggleSidebarCollapsed, setSidebarCollapsed, sidebarPinned, setSidebarPinned } =
    useContext(SidebarCollapsedContext)

  const { isLGScreen } = useContext(MediaQueriesContext)
  const permissionData = data?.me?.permissions

  useOnClickOutside({ handler: () => !sidebarPinned && setSidebarCollapsed(true), ref: navSidebar })

  const showReservation = ((): boolean => {
    if (!permissionData) return false
    const { taxiFeature, sharedVehicleFeature, canViewLeaseBikeFeature, rentalCarFeature } = permissionData

    return taxiFeature || sharedVehicleFeature || canViewLeaseBikeFeature || rentalCarFeature
  })()

  if (loading) return <Skeleton height={52} />

  // TODO: These error handling should be redirects to dedicated pages instead of components within the sidebar component.
  //       The App.tsx has now error handling for session expired. These errors should follow that example.
  // @ts-ignore
  if (error && error.message.match(/code 500/)) return <InternalServerErrorPage />
  // @ts-ignore
  if (error && error.message.match(/code 401/)) return <SessionExpiredPage />

  if (error && 'networkError' in error && /^ServerError/.test(error?.networkError?.toString() || ''))
    return <InternalServerErrorPage />

  if (!isVisible) {
    return (
      <div data-testid="sidebar-placeholder" className={Styles.noSidebar}>
        {children}
      </div>
    )
  }

  const handleClick = (): void => {
    if (!sidebarPinned) toggleSidebarCollapsed()
  }

  const closeButtonHandler = (): void => {
    if (sidebarPinned) setSidebarPinned(false)
    toggleSidebarCollapsed()
  }

  const classNames = ((): string => {
    const classes: string[] = [Styles.sideBar]

    if (sidebarCollapsed) classes.push(Styles.collapsed)
    if (isDarkSidebar) classes.push(Styles.dark)

    return classes.join(' ')
  })()

  return (
    <div id="sidebar" className={classNames}>
      <nav
        id="nav-sidebar"
        aria-label={t('Sidebar navigation')}
        className={`${Styles.navigation} ${!isLGScreen ? Styles.isNotPinned : ''}`}
        role="navigation"
        ref={navSidebar}
      >
        <div className={Styles.topElements}>
          {isLGScreen && (
            <div tabIndex={0} className={Styles.pinWrapper} aria-label="Pin menu" role="button">
              <Icon
                id="pin-icon"
                className={`${Styles.pin} ${sidebarPinned ? Styles.isPinned : ''}`}
                name="pin"
                width={16}
                height={16}
                onClick={(): void => setSidebarPinned(!sidebarPinned)}
              />
            </div>
          )}

          <div
            onClick={closeButtonHandler}
            className={Styles.closeButton}
            id="close-menu"
            data-testid="close-menu"
            tabIndex={0}
            aria-label="Close menu"
            role="button"
          >
            <Typography className={Styles.name} variant="h2" element="span">
              {t('Close')}
            </Typography>

            <Icon name="cross" width={16} height={16} />
          </div>
        </div>

        {!!currentTheme?.logo?.uri && (
          <div className={Styles.logo}>
            <div className={Styles.imageContainer}>
              <LazyImage alt="Brand logo" src={currentTheme.logo.uri} />
            </div>
          </div>
        )}

        <div id="navigation-items" className={Styles.navigationItems}>
          <Typography variant="body-2" className={Styles.subHeader}>
            {t('General')}
          </Typography>

          <NavigationItem
            isActiveOnChildRoutes={false}
            name={t('Home')}
            icon="house"
            href={routes[ROUTE_NAMES.DASHBOARD]}
            onClick={handleClick}
          />

          <NavigationItem
            name={t('Overview')}
            icon="calculator"
            href={routes[ROUTE_NAMES.MOBILITY_EVENTS]}
            onClick={handleClick}
          />

          {showReservation && (
            <NavigationItem
              isActiveOnChildRoutes={false}
              name={t('Transport')}
              icon="transport"
              href={routes[ROUTE_NAMES.TRANSPORT]}
              onClick={handleClick}
            />
          )}

          <Typography variant="body-3" className={Styles.subHeader}>
            {ucFirst(platform)}
          </Typography>

          <NavigationItem
            name={t('My finances')}
            icon="km-gauge"
            href={routes[ROUTE_NAMES.SETTLEMENTS]}
            onClick={handleClick}
          />

          {!isProduction && (
            <NavigationItem
              name={t('My finances')}
              icon="km-gauge"
              beta
              href={routes[ROUTE_NAMES.FINANCES]}
              onClick={handleClick}
            />
          )}

          {permissionData?.approvals && (
            <NavigationItem
              name={t('Approvals')}
              icon="text-check-light"
              href={routes[ROUTE_NAMES.APPROVALS]}
              onClick={handleClick}
            />
          )}

          {permissionData?.canAssignReplacementManager && !isProduction && (
            <NavigationItem
              name={t('Substitute manager')}
              icon="fiat-classic"
              href={routes[ROUTE_NAMES.SUBSTITUTE_MANAGER]}
              onClick={handleClick}
            />
          )}

          {permissionData?.viewDirectDebitInvoices && (
            <NavigationItem
              name={t('Payments and invoices')}
              icon="receipt"
              href={routes[ROUTE_NAMES.PAYMENTS]}
              onClick={handleClick}
            />
          )}

          {permissionData?.viewSplitBillingInvoices && (
            <NavigationItem
              name={t('Billing')}
              icon="receipt"
              href={routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS]}
              onClick={handleClick}
            />
          )}

          <Typography variant="body-2" className={Styles.subHeader}>
            {t('Insight & Management')}
          </Typography>

          <NavigationItem
            name={t('Means of transport')}
            icon="traffic"
            href={routes[ROUTE_NAMES.PRODUCTS]}
            onClick={handleClick}
          />

          <NavigationItem
            name={t('CO₂ emission')}
            icon="weather"
            href={routes[ROUTE_NAMES.EMISSION]}
            onClick={handleClick}
          />

          <NavigationItem
            name={t('Cards and services')}
            icon="card"
            href={routes[ROUTE_NAMES.CARDS_AND_SERVICES]}
            onClick={handleClick}
          />

          <NavigationItem
            name={t('Favourites')}
            icon="star"
            href={routes[ROUTE_NAMES.FAVOURITES]}
            onClick={handleClick}
          />

          <Typography variant="body-2" className={Styles.subHeader}>
            {t('Contact')}
          </Typography>

          <NavigationItem
            name={t('Customer service')}
            icon="help"
            href={routes[ROUTE_NAMES.HELP]}
            onClick={handleClick}
          />
        </div>

        {showEnvVersion && (
          <p className={Styles.envVersion}>
            {environment} - {version}
          </p>
        )}
      </nav>

      <BreadcrumbsProvider>
        <Content>{children}</Content>
      </BreadcrumbsProvider>
    </div>
  )
}
