export type TIconName =
  | 'airport'
  | 'alert'
  | 'approve'
  | 'arrow-left'
  | 'arrow-right-light'
  | 'arrow-right'
  | 'arrow-turn-left'
  | 'arrow-turn-right'
  | 'bell'
  | 'bicycle'
  | 'boat'
  | 'budget'
  | 'bug-fix'
  | 'building'
  | 'bus-ticket'
  | 'bus'
  | 'calculator'
  | 'calendar'
  | 'camera'
  | 'car-empty'
  | 'car'
  | 'card'
  | 'card_lost'
  | 'caret-down'
  | 'charger'
  | 'chat'
  | 'check-circle-outline'
  | 'check'
  | 'checkbox-checked'
  | 'checkbox-unchecked'
  | 'checkboxes'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right-bold'
  | 'chevron-right'
  | 'chevron-up'
  | 'clear'
  | 'clock'
  | 'close-circle'
  | 'cross-bold'
  | 'cross'
  | 'delete'
  | 'donkey_republic'
  | 'download'
  | 'e-ticket'
  | 'ebike'
  | 'edit'
  | 'electric'
  | 'electricity'
  | 'empty-declaration'
  | 'empty-reservation'
  | 'en'
  | 'eneco_dongle'
  | 'euro_hand'
  | 'external'
  | 'external-link'
  | 'eye-hide'
  | 'eye-show'
  | 'fiat-classic'
  | 'filter'
  | 'fuel-card'
  | 'fuel'
  | 'gps-logger'
  | 'graph'
  | 'hand_pay'
  | 'help'
  | 'homepointer'
  | 'house'
  | 'icon-check-success'
  | 'incomplete'
  | 'info-circle'
  | 'info'
  | 'key'
  | 'km-gauge'
  | 'language'
  | 'leaves'
  | 'legs'
  | 'light-rail-station'
  | 'loading'
  | 'location-cross'
  | 'location'
  | 'lock'
  | 'logout'
  | 'mail'
  | 'management'
  | 'mappointer'
  | 'menu-light'
  | 'menu'
  | 'merge-bold'
  | 'merge'
  | 'metro'
  | 'minus'
  | 'monitor'
  | 'more-vertical'
  | 'more'
  | 'motorcycle'
  | 'nl'
  | 'other-expenses'
  | 'palette'
  | 'parking'
  | 'pdf'
  | 'pencil'
  | 'phone-light'
  | 'phone'
  | 'pin-line'
  | 'pin'
  | 'plus'
  | 'products'
  | 'profile'
  | 'public-transport-card'
  | 'public-transport'
  | 'qr'
  | 'question'
  | 'receipt-light'
  | 'receipt'
  | 'refresh'
  | 'refund'
  | 'reisbalans-partial'
  | 'rental_car'
  | 'road'
  | 'scooter'
  | 'search'
  | 'security-shield'
  | 'select-alt'
  | 'select'
  | 'settings'
  | 'shed'
  | 'shield'
  | 'shuttel_check'
  | 'shuttel_my_wheels'
  | 'shuttel_sixt'
  | 'sign-out'
  | 'sort'
  | 'star'
  | 'suitcase'
  | 'switch'
  | 'taxi'
  | 'text-check-light'
  | 'tick-light'
  | 'tick'
  | 'traffic'
  | 'train'
  | 'tram'
  | 'transport'
  | 'tranzer_etickets'
  | 'trash'
  | 'undo'
  | 'unknown'
  | 'user-fee'
  | 'user'
  | 'walking'
  | 'warning'
  | 'weather'
  | 'work'
  | 'yellowbrick-product'
export const iconNames: TIconName[] = [
  'airport',
  'alert',
  'approve',
  'arrow-left',
  'arrow-right-light',
  'arrow-right',
  'arrow-turn-left',
  'arrow-turn-right',
  'bell',
  'bicycle',
  'boat',
  'budget',
  'bug-fix',
  'building',
  'bus-ticket',
  'bus',
  'calculator',
  'calendar',
  'camera',
  'car-empty',
  'car',
  'card',
  'card_lost',
  'caret-down',
  'charger',
  'chat',
  'check-circle-outline',
  'check',
  'checkbox-checked',
  'checkbox-unchecked',
  'checkboxes',
  'chevron-down',
  'chevron-left',
  'chevron-right-bold',
  'chevron-right',
  'chevron-up',
  'clear',
  'clock',
  'close-circle',
  'cross-bold',
  'cross',
  'delete',
  'donkey_republic',
  'download',
  'e-ticket',
  'ebike',
  'edit',
  'electric',
  'electricity',
  'empty-declaration',
  'empty-reservation',
  'en',
  'eneco_dongle',
  'euro_hand',
  'external',
  'external-link',
  'eye-hide',
  'eye-show',
  'fiat-classic',
  'filter',
  'fuel-card',
  'fuel',
  'gps-logger',
  'graph',
  'hand_pay',
  'help',
  'homepointer',
  'house',
  'icon-check-success',
  'incomplete',
  'info-circle',
  'info',
  'key',
  'km-gauge',
  'language',
  'leaves',
  'legs',
  'light-rail-station',
  'loading',
  'location-cross',
  'location',
  'lock',
  'logout',
  'mail',
  'management',
  'mappointer',
  'menu-light',
  'menu',
  'merge-bold',
  'merge',
  'metro',
  'minus',
  'monitor',
  'more-vertical',
  'more',
  'motorcycle',
  'nl',
  'other-expenses',
  'palette',
  'parking',
  'pdf',
  'pencil',
  'phone-light',
  'phone',
  'pin-line',
  'pin',
  'plus',
  'products',
  'profile',
  'public-transport-card',
  'public-transport',
  'qr',
  'question',
  'receipt-light',
  'receipt',
  'refresh',
  'refund',
  'reisbalans-partial',
  'rental_car',
  'road',
  'scooter',
  'search',
  'security-shield',
  'select-alt',
  'select',
  'settings',
  'shed',
  'shield',
  'shuttel_check',
  'shuttel_my_wheels',
  'shuttel_sixt',
  'sign-out',
  'sort',
  'star',
  'suitcase',
  'switch',
  'taxi',
  'text-check-light',
  'tick-light',
  'tick',
  'traffic',
  'train',
  'tram',
  'transport',
  'tranzer_etickets',
  'trash',
  'undo',
  'unknown',
  'user-fee',
  'user',
  'walking',
  'warning',
  'weather',
  'work',
  'yellowbrick-product',
]
